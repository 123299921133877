import revive_payload_client_4sVQNw7RlN from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/stage.efxpp.ch/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/stage.efxpp.ch/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/stage.efxpp.ch/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/forge/stage.efxpp.ch/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/home/forge/stage.efxpp.ch/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import error_handler_VFH3VvK7yG from "/home/forge/stage.efxpp.ch/plugins/error-handler.js";
import sentry_client_GoGQuZo4Et from "/home/forge/stage.efxpp.ch/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_AUP22rrBAc,
  error_handler_VFH3VvK7yG,
  sentry_client_GoGQuZo4Et
]