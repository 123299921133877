import { default as archiveftmjfc2PkMMeta } from "/home/forge/stage.efxpp.ch/pages/auth/archive.vue?macro=true";
import { default as _91id_93HaFLFmtFStMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/[id].vue?macro=true";
import { default as add8qOYUBbD3VMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/add.vue?macro=true";
import { default as _91id_93QBBF70diycMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/[id].vue?macro=true";
import { default as addWyMElt5FY4Meta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/add.vue?macro=true";
import { default as _91id_93aMadBt5V7HMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/delete/[id].vue?macro=true";
import { default as indexEw7K3PYm39Meta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/index.vue?macro=true";
import { default as _91id_93Kuv3fjsYgtMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/delete/[id].vue?macro=true";
import { default as indexDF5ub9gm6NMeta } from "/home/forge/stage.efxpp.ch/pages/auth/contacts/index.vue?macro=true";
import { default as indexTmeW52NDryMeta } from "/home/forge/stage.efxpp.ch/pages/auth/index.vue?macro=true";
import { default as contactsqTPxfanZ1kMeta } from "/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/contacts.vue?macro=true";
import { default as delete3Ebb3aCnjdMeta } from "/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/delete.vue?macro=true";
import { default as editF5nwfZoGCZMeta } from "/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/edit.vue?macro=true";
import { default as indexWmkcnTDU5CMeta } from "/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/index.vue?macro=true";
import { default as addbU9yanq1wwMeta } from "/home/forge/stage.efxpp.ch/pages/auth/projects/add.vue?macro=true";
import { default as deleteSVbN3Ei6BWMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/delete.vue?macro=true";
import { default as edit2LCFvU3JKvMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/edit.vue?macro=true";
import { default as indexx9oH2cVVHyMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/index.vue?macro=true";
import { default as deleteqdTMXN8UyKMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue?macro=true";
import { default as editSOLlCgFblgMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue?macro=true";
import { default as addstEsc1zGXcMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/add.vue?macro=true";
import { default as _91projectId_93d7YNUmmRDaMeta } from "/home/forge/stage.efxpp.ch/pages/auth/schedule/add/[projectId].vue?macro=true";
import { default as _91id_93ktxz1TiPbtMeta } from "/home/forge/stage.efxpp.ch/pages/auth/users/[id].vue?macro=true";
import { default as addK7r1UresGtMeta } from "/home/forge/stage.efxpp.ch/pages/auth/users/add.vue?macro=true";
import { default as _91id_93VLO76FlS3vMeta } from "/home/forge/stage.efxpp.ch/pages/auth/users/delete/[id].vue?macro=true";
import { default as indexkIAwptMUcsMeta } from "/home/forge/stage.efxpp.ch/pages/auth/users/index.vue?macro=true";
import { default as profileEVxmPNPvuaMeta } from "/home/forge/stage.efxpp.ch/pages/auth/users/profile.vue?macro=true";
import { default as indextwq3ekD9VbMeta } from "/home/forge/stage.efxpp.ch/pages/index.vue?macro=true";
import { default as loginSeb1XrpKZnMeta } from "/home/forge/stage.efxpp.ch/pages/login.vue?macro=true";
import { default as _91id_93UT79iwnu0fMeta } from "/home/forge/stage.efxpp.ch/pages/snapshot/schedule/[id].vue?macro=true";
export default [
  {
    name: "auth-archive",
    path: "/auth/archive",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/archive.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-id",
    path: "/auth/contacts/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-add",
    path: "/auth/contacts/add",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-id",
    path: "/auth/contacts/corporations/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-add",
    path: "/auth/contacts/corporations/add",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-delete-id",
    path: "/auth/contacts/corporations/delete/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations",
    path: "/auth/contacts/corporations",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/corporations/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-delete-id",
    path: "/auth/contacts/delete/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts",
    path: "/auth/contacts",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-contacts",
    path: "/auth/projects/:id()/contacts",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-delete",
    path: "/auth/projects/:id()/delete",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-edit",
    path: "/auth/projects/:id()/edit",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id",
    path: "/auth/projects/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-add",
    path: "/auth/projects/add",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/projects/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-delete",
    path: "/auth/schedule/:id()/delete",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-edit",
    path: "/auth/schedule/:id()/edit",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id",
    path: "/auth/schedule/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-delete",
    path: "/auth/schedule/:id()/sections/:sectionId()/delete",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-edit",
    path: "/auth/schedule/:id()/sections/:sectionId()/edit",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-add",
    path: "/auth/schedule/:id()/sections/add",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/[id]/sections/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-add-projectId",
    path: "/auth/schedule/add/:projectId()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/schedule/add/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-id",
    path: "/auth/users/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-add",
    path: "/auth/users/add",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/users/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-delete-id",
    path: "/auth/users/delete/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/users/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users",
    path: "/auth/users",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/users/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-profile",
    path: "/auth/users/profile",
    component: () => import("/home/forge/stage.efxpp.ch/pages/auth/users/profile.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/stage.efxpp.ch/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/forge/stage.efxpp.ch/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "snapshot-schedule-id",
    path: "/snapshot/schedule/:id()",
    component: () => import("/home/forge/stage.efxpp.ch/pages/snapshot/schedule/[id].vue").then(m => m.default || m)
  }
]